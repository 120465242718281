<template>
  <!-- <div> -->
    <div class="self_answer">
      <button
        @click="openDialog"
        type="button"
        class="v-expansion-panel-header flex-row-reverse button_expand"
      >
        <div class="v-expansion-panel-header__icon">
          <i
            v-if="dialog"
            aria-hidden="true"
            class="v-icon notranslate ma-n3 mdi mdi-menu-down theme--light icon-show-img"
          >
          </i>
          <i
            v-else
            aria-hidden="true"
            class="v-icon notranslate ma-n3 mdi mdi-menu-up theme--light icon-show-img"
          >
          </i>
        </div>
        <div class="sub-title white--text ml-5">Frequently Asked Questions</div>
      </button>
      <v-expansion-panels
        v-if="dialog"
        v-model="activeItem"
        tile accordion
        class="mb-6">
        <v-expansion-panel
          v-for="(item, i) in faQuestionList"
          :key="i"
        >
          <v-expansion-panel-header
            :class="{ 'sub-title': i == activeItem }"
            hide-actions
              class="question__content"
          >
            <div>
              <v-icon color="#7811C9" class="ma-n1" size="35">{{
                  i === activeItem ? "mdi-minus-box-outline" : "mdi-plus-box-outline"
                }}</v-icon>
              <span class="ml-4 question__content">{{item.question}}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            v-html="item.answer"
            class="question__answer"
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeItem: null,
      faQuestionList: {},
      dialog: false,
    };
  },
  created() {
    this.$api.faq.fetchFAQ().then((data) => {
      this.faQuestionList = data.faQuestionList;
    });
  },
  methods: {
    openDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.frequetly-questions {
  padding: 0 50px;
  .question__list {
    background-color: transparent;
    > .v-expansion-panel-content__wrap {
      padding: 3px;
    }
  }
  .v-expansion-panel-header {
    min-height: auto !important;
    padding: 13px 10px !important;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-content__wrap {
    padding: 10px 20px;
  }
}
.question__content {
  // background-color: #f2e7fa;
   font-family: 'Montserrat';
 font-style: normal !important;
   font-size: 16px;
    background-color: #f2e7fa;
}
.question__answer {
  //text-indent: 10px;
  text-align: left;
  font-family: 'Montserrat';
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
   font-size: 16px;
    background-color: #f2e7fa;
}
.button_expand {
  background-color: rgb(188, 136, 228);
  border-color: rgb(188, 136, 228);
  // margin-bottom: 50px;
}
.v-expansion-panel-header {
  width: 100%;
  padding: 13px 10px;
}
.sub-title {
  font-size: 16px;
}
.v-application .flex-row-reverse {
  flex-direction: row !important;
}
.processing_work {
  width: 100%;
}
.icon-show-img{
  font-size: 50px;
  color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
}
.v-expansion-panel-content {
  display: inherit;
}
.self_answer {
  margin-bottom: 40px;
}
</style>
