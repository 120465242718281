<template>
  <div class="expand_all">
    <button
      @click="actionExpandAll"
      type="button"
      class="v-expansion-panel-header flex-row-reverse button_expand"
      style=""
    >
      <div
        class="sub-title white--text ml-5">expand all</div>
      <div class="v-expansion-panel-header__icon">
        <i
          aria-hidden="true"
          class="v-icon notranslate ma-n3 mdi mdi-menu-up theme--light expand-all-style"
        >
        </i>
      </div>
    </button>
    <button
      v-if="showResYou"
      type="button"
      class="v-expansion-panel-header flex-row-reverse button_expand button_expand-you"
    >
      <div class="sub-title white--text">You</div>
    </button>
    <button
      v-if="showResCrowd"
      type="button"
      class="v-expansion-panel-header flex-row-reverse button_crowd"
    >
      <div class="sub-title dark--text">crowd</div>
    </button>
    <!-- <div class="content-expand-all"><p></p></div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      openRes: this.expandAll,
    };
  },
  computed: {
    ...mapGetters({
      expandAll: 'showComponent/expandAll',
      showResCrowd: 'showComponent/showResCrowd',
      showResYou: 'showComponent/showResYou',
    }),
  },
  methods: {
    actionExpandAll() {
      this.openRes = !this.openRes;
      this.$store.commit('showComponent/setExpandAll', this.openRes);
    },
  },
};
</script>

<style lang="scss" scoped>
.button_expand {
  background-color: rgb(188, 136, 228);
  border-color: rgb(188, 136, 228);
  inline-size: fit-content;
  float: left;
  border-radius: 40px;
  margin-bottom: 30px;
}
.button_crowd {
  background-color: #FFCBCB;
  border-color: #FFCBCB;
  inline-size: fit-content;
  float: left;
  border-radius: 40px;
  margin-bottom: 30px;
}
.button_expand-you {
  // margin: 0 8px;
}
.v-expansion-panel-header {
  padding: 5px 15px;
  min-height: 8px;
}
.expand_all {
  border-bottom: 1px solid #a158d9;
  margin: 0 10px 35px 10px;
  display: flex;
  justify-content: space-between;
}
.content-expand-all {
  height: 50px;
}
.expand-all-style {
  font-size: 50px;
  color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
}
</style>
